<template>
  <div class="calendar-container">
    <a-modal
      v-model="visibleModalManageKegiatan"
      class="mod"
      :title="status.isEdit ? 'Form Ubah Kegiatan' : 'Form Tambah Kegiatan'"
      @cancel="toggleModalManageKegiatan"
      :destroyOnClose="true"
      centered
    >
      <form-kegiatan
        :dataKegiatan="newKegiatan"
        :isDisabled="false"
        @handle-change="handleChange"
      />
      <template slot="footer">
        <div class="d-flex align-items-center p-2">
          <div>
            <a-button v-if="status.isEdit" class="text-danger p-0 shadow-none" size="large" key="extra" type="link" :loading="loadingDeleteKegiatan" @click="handleDeleteKegiatan">
              Hapus Kegiatan
            </a-button>
          </div>
          <div class="ml-auto">
            <a-button size="large" key="back" @click="toggleModalManageKegiatan">
              Batal
            </a-button>
            <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageKegiatan" @click="handleOkModalManageKegiatan">
              {{status.isEdit ? 'Ubah' : 'Tambah'}} Sekarang
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
    <a-button @click.prevent="toggleModalManageKegiatan('add')" size="large" class="button-tambah" type="primary"><a-icon type="plus" />Tambah Kegiatan</a-button>
    <calendar-kegiatan
      :listKegiatan="listKegiatan"
      @selected-date="handleSelectDate"
    />
  </div>
</template>

<script>
import moment from 'moment'
const FormKegiatan = () => import('@/components/app/AdminHR/Kegiatan/FormKegiatan')
const CalendarKegiatan = () => import('@/components/app/Shared/CalendarKegiatan')
export default {
  name: 'Kegiatan',
  components: {
    FormKegiatan,
    CalendarKegiatan,
  },
  data() {
    return {
      visibleModalManageKegiatan: false,
      loadingActionModalManageKegiatan: false,
      loadingDeleteKegiatan: false,
      listKegiatan: [],
      status: {
        isEdit: false,
        isCreate: false,
      },
      newKegiatan: {
        jenis: null,
        nama: null,
        deskripsi: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        status_pengulangan: 'inactive',
        pengulangan_sampai: null,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      this.newKegiatan[column] = value
      if (column === 'jenis') {
        if (value === 'libur') {
          this.newKegiatan.tanggal_akhir = null
        } else if (value === 'acara') {
          this.newKegiatan.status_pengulangan = 'inactive'
          this.newKegiatan.pengulangan_sampai = null
        }
      }
      if (column === 'status_pengulangan') {
        if (value === 'inactive') {
          this.newKegiatan.pengulangan_sampai = null
        }
      }
      console.log(this.newKegiatan)
    },
    toggleModalManageKegiatan(status, data) {
      // console.log(status)
      this.visibleModalManageKegiatan = !this.visibleModalManageKegiatan
      if (!this.visibleModalManageKegiatan) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newKegiatan = {
            jenis: null,
            nama: null,
            deskripsi: null,
            tanggal_awal: null,
            tanggal_akhir: null,
            status_pengulangan: 'inactive',
            pengulangan_sampai: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newKegiatan = {
            jenis: data.jenis,
            nama: data.nama,
            deskripsi: data.deskripsi,
            tanggal_awal: moment(data.tanggal_awal),
            tanggal_akhir: moment(data.tanggal_akhir),
            status_pengulangan: data.status_pengulangan,
            pengulangan_sampai: data.pengulangan_sampai,
          }
          console.log(this.newKegiatan)
        }
      }
    },
    handleOkModalManageKegiatan() {
      const content = `${this.status.isEdit ? `Apakah anda yakin ingin mengubah kegiatan ${this.newKegiatan.nama}?` : 'Apakah anda yakin ingin membuat kegiatan baru ini?'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Peringatan',
          description: 'Semua kolom wajib diisi',
        })
      } else {
        // if (this.periodeIsExist() && this.status.isCreate) {
        //   return this.$notification.error({
        //     message: 'Gagal',
        //     description: 'kegiatan yang anda buat sudah ada disistem. Gunakan Ubah untuk mengubah kegiatan',
        //   })
        // }
        // console.log(this.periodeIsExist())
        this.$confirm({
          title: 'Peringatan',
          content: (
            <div class="d-flex flex-column">
              <p>{content}</p>
              <p class="text-danger mt-2">perubahan ini akan mempengaruhi sistem absensi pegawai</p>
            </div>
          ),
          onOk: () => {
            this.loadingActionModalManageKegiatan = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataKegiatan: this.newKegiatan,
            } : {
              dataKegiatan: this.newKegiatan,
            }
            this.$store.dispatch(`kegiatan/${this.status.isEdit ? 'EDIT' : 'POST'}_KEGIATAN`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageKegiatan = false
                this.toggleModalManageKegiatan()
                this.fetchDataKegiatan()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Kegiatan berhasil diubah' : 'Kegiatan baru berhasil ditambahkan'
                  this.$notification.success({
                    message: 'Berhasil',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Kegiatan gagal diubah' : 'Kegiatan baru gagal ditambahkan'
                  this.$notification.error({
                    message: 'Gagal',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Ubah Sekarang' : 'Tambah Sekarang',
          cancelText: 'Batal',
        })
      }
    },
    handleDeleteKegiatan() {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div class="d-flex flex-column">
            <p>Apakah anda yakin ingin menghapus kegiatan <b>{this.newKegiatan.nama}</b> ?</p>
            <p class="text-danger mt-2">perubahan ini akan mempengaruhi sistem absensi pegawai</p>
          </div>
        ),
        onOk: () => {
          this.loadingDeleteKegiatan = true
          this.$store.dispatch('kegiatan/DELETE_KEGIATAN', {
            id: this.idEdit,
          })
            .then(isSuccess => {
              this.toggleModalManageKegiatan()
              this.fetchDataKegiatan()
              this.loadingDeleteKegiatan = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Kegiatan berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Kegiatan gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    handleSelectDate(date) {
      this.toggleModalManageKegiatan('edit', date)
    },
    fetchDataKegiatan() {
      this.$store.dispatch('kegiatan/FETCH_KEGIATAN')
        .then(res => {
          this.listKegiatan = res
        })
    },
  },
  mounted() {
    this.fetchDataKegiatan()
  },
  computed: {
    isValidateForm() {
      if (this.newKegiatan.nama && this.newKegiatan.jenis && this.newKegiatan.tanggal_awal) {
        if (this.newKegiatan.jenis === 'libur') {
          if (this.newKegiatan.status_pengulangan) {
            if (this.newKegiatan.status_pengulangan === 'active' && this.newKegiatan.pengulangan_sampai) {
              return false
            } else if (this.newKegiatan.status_pengulangan === 'inactive') {
              return false
            }
            return true
          }
          return true
        } else if (this.newKegiatan.jenis === 'acara') {
          if (this.newKegiatan.tanggal_akhir) {
            return false
          }
          return true
        }
        return false
      }
      return true
    },
  },
}
</script>

<style lang="css">
.calendar-container {
  position: relative;
  margin-top: -11px;
}

.calendar-container .button-tambah {
  position: absolute;
  top: 12px;
  right: 0;
}
</style>
